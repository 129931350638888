.rdt_TableCol {
  font-size: 1.6rem;
  font-weight: 700;
}

.data-table-extensions-filter, .div#rc-tabs-0-panel-1  {
  // background-color: #fff !important;
}

.rdt_TableCell {
  font-size: 1.4rem;
}

.data-table-extensions > .data-table-extensions-action > .dropdown {
  min-width: 8rem !important;
  padding: 7px !important;
  background-color: #ffffff !important;
}

.data-table-extensions > .data-table-extensions-action > .dropdown button {
  color: #58595a !important;
  font-size: 1.4rem !important;
}
@media (max-width: 767.98px) {
 
}
.sc-dmRaPn.iXRfeU, .ant-tabs-content.ant-tabs-content-top .iXRfeU {
  overflow-x: none !important;
  overflow-y: none !important;
}
// for data table text overflow
// for data table text overflow
.bMgaAx div:first-child, .dBbhDz div:first-child{
  white-space: initial !important;
}

.data-table-extensions>.data-table-extensions-action>button:after{
  margin-top: 20px !important;
}